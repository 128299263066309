











































import { Component, Vue, Watch } from "vue-property-decorator";
import Snackbar from '../components/Snackbar.vue';
import formatter from "@/mixins/formatter";
import { State } from "vuex-class";
import { AuthUser } from "@/smartmsi";
import Toolbar from "@/layouts/components/Toolbar.vue";
import Drawer from "@/layouts/components/Drawer.vue";

@Component({ components: { Drawer, Toolbar, Snackbar }, mixins: [formatter] })
export default class Main extends Vue {
  metaInfo() {
    return {
      title: "Smart MSI",
    };
  }

  @State user!: AuthUser;
  appLoadComplete = false;
  showConfigUploads = true
  hideTimeout: number | null = null;
  created() {
    if (this.user.role === 'admin') {
      const resources = [
        this.$store.dispatch('loadCountries'),
        this.$store.dispatch('loadEntities'),
        this.$store.dispatch('loadLegalTypologies'),
        this.$store.dispatch('loadStockMarkets'),
        this.$api.get('activity?expand=sector&per-page=0').then(j => this.$store.state.activities = j.data),
        this.$api.get('site/config').then(j => {
          this.$store.state.rules = j.data.rules;
          this.$store.state.availableLanguages = j.data.availableLanguages;
          this.$store.state.attributesLabels = j.data.attributesLabels;
          Object.keys(j.data.attributesLabels).forEach(lang => {
            this.$i18n.mergeLocaleMessage(lang, { models: j.data.attributesLabels[lang] });

          });
        }),
      ];
      Promise.all(resources).then(() => this.appLoadComplete = true);
    } else {
      const resources = [
        this.$store.dispatch('loadCountries'),
        this.$store.dispatch('loadEntities'),
        this.$store.dispatch('loadLegalTypologies'),
        this.$store.dispatch('loadStockMarkets'),
        this.$api.get('activity?expand=sector&per-page=0').then(j => this.$store.state.activities = j.data),
        this.$api.get('site/config').then(j => {
          this.$store.state.rules = j.data.rules;
          this.$store.state.availableLanguages = j.data.availableLanguages;
          this.$store.state.attributesLabels = j.data.attributesLabels;
          Object.keys(j.data.attributesLabels).forEach(lang => {
            this.$i18n.mergeLocaleMessage(lang, { models: j.data.attributesLabels[lang] });

          });
        }),
      ];
      Promise.all(resources).then(() => this.appLoadComplete = true);
    }
  }


  mounted() {
    this.$root.$confirm = this.$refs.confirm;
  }

  @Watch('$store.state.configUploads', { deep: true })
  onConfigUploadsChange(newUploads: any[]) {
    if (newUploads.length) {
      this.showConfigUploads = true;

      // Clear any existing timeout to reset the timer if new items are added
      if (this.hideTimeout) clearTimeout(this.hideTimeout);

      // Set a timeout to hide configUploads after 5 seconds
      this.hideTimeout = setTimeout(() => {
        this.showConfigUploads = false;
      }, 5000); // 5000 ms = 5 seconds
    }
  }

  beforeDestroy() {
    // Clear the timeout to avoid memory leaks
    if (this.hideTimeout) clearTimeout(this.hideTimeout);
  }
}
