var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mb-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "card",
                [
                  _c("div", { staticClass: "card-header" }, [
                    _c("div", { staticClass: "title-and-button" }, [
                      _c(
                        "div",
                        {
                          staticClass: "card-title selected-item",
                          on: {
                            click: function ($event) {
                              return _vm.viewIndex()
                            },
                          },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              "Portefeuille : " +
                                _vm._s(_vm.portfolioName) +
                                " ( " +
                                _vm._s(_vm.portfolioDevise) +
                                ")"
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      [
                        _c(
                          "v-expansion-panels",
                          {
                            attrs: { value: _vm.isPanelOpen, multiple: "" },
                            model: {
                              value: _vm.isPanelOpen,
                              callback: function ($$v) {
                                _vm.isPanelOpen = $$v
                              },
                              expression: "isPanelOpen",
                            },
                          },
                          [
                            _c(
                              "v-expansion-panel",
                              [
                                _c("v-expansion-panel-header", [
                                  _vm._v("Filters"),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                multiple: "",
                                                items: _vm.countries,
                                                label: _vm.$t(
                                                  "models.country.self"
                                                ),
                                                "item-text": function (item) {
                                                  return item.name != "null" &&
                                                    item.code != null
                                                    ? _vm.translate(item.name) +
                                                        ": (" +
                                                        item.code +
                                                        ")"
                                                    : "" +
                                                        _vm.translate(item.name)
                                                },
                                                "item-value": "id",
                                              },
                                              on: {
                                                change: _vm.onChangeCountry,
                                              },
                                              model: {
                                                value: _vm.countryFilterId,
                                                callback: function ($$v) {
                                                  _vm.countryFilterId = $$v
                                                },
                                                expression: "countryFilterId",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                multiple: "",
                                                items: _vm.sectors,
                                                label:
                                                  _vm.$t("models.sector.self"),
                                                "item-text": function (item) {
                                                  return item.name != "null" &&
                                                    item.domain != null
                                                    ? _vm.translate(item.name) +
                                                        ": (" +
                                                        item.domain +
                                                        ")"
                                                    : "" +
                                                        _vm.translate(
                                                          item.domain
                                                        )
                                                },
                                                "item-value": "id",
                                              },
                                              on: {
                                                change: _vm.onChangeSector,
                                              },
                                              model: {
                                                value: _vm.sectorId,
                                                callback: function ($$v) {
                                                  _vm.sectorId = $$v
                                                },
                                                expression: "sectorId",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                items: _vm.indices,
                                                label: _vm.$t("stock.title"),
                                                "item-text": function (item) {
                                                  return item.name
                                                },
                                                "item-value":
                                                  "stock_exchange_id",
                                              },
                                              model: {
                                                value: _vm.indexId,
                                                callback: function ($$v) {
                                                  _vm.indexId = $$v
                                                },
                                                expression: "indexId",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center justify-content-space-between",
                                              },
                                              [
                                                _c(
                                                  "v-subheader",
                                                  { staticClass: "pl-0" },
                                                  [_vm._v(" score range ")]
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ma-2",
                                                        attrs: {
                                                          small: "",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setConforme(
                                                              "score"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Conforme")]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ma-2",
                                                        attrs: {
                                                          small: "",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setNonConforme(
                                                              "score"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Non Conforme")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("v-range-slider", {
                                              attrs: {
                                                max: "200",
                                                min: "0",
                                                "thumb-label": "",
                                                "thumb-size": 26,
                                              },
                                              model: {
                                                value: _vm.scoreRange,
                                                callback: function ($$v) {
                                                  _vm.scoreRange = $$v
                                                },
                                                expression: "scoreRange",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-expansion-panels",
                                      {
                                        attrs: {
                                          value: _vm.isPanelFiltersOpen,
                                          multiple: "",
                                        },
                                        model: {
                                          value: _vm.isPanelFiltersOpen,
                                          callback: function ($$v) {
                                            _vm.isPanelFiltersOpen = $$v
                                          },
                                          expression: "isPanelFiltersOpen",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-expansion-panel",
                                          [
                                            _c("v-expansion-panel-header", [
                                              _vm._v("Ratios Financiers"),
                                            ]),
                                            _c(
                                              "v-expansion-panel-content",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-center align-items-center",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center justify-content-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      {
                                                                        staticClass:
                                                                          "pl-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " solidity range "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setConforme(
                                                                                    "solidity"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setNonConforme(
                                                                                    "solidity"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Non Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-range-slider",
                                                                  {
                                                                    attrs: {
                                                                      max: "100",
                                                                      min: "0",
                                                                      "thumb-label":
                                                                        "",
                                                                      "thumb-size": 26,
                                                                      filled:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.solidityRange,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.solidityRange =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "solidityRange",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "8" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center justify-content-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      {
                                                                        staticClass:
                                                                          "pl-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Gestion des liquidités "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setConforme(
                                                                                    "liquidity"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setNonConforme(
                                                                                    "liquidity"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Non Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-range-slider",
                                                                  {
                                                                    attrs: {
                                                                      max: "10",
                                                                      min: "0",
                                                                      "thumb-label":
                                                                        "",
                                                                      "thumb-size": 26,
                                                                      filled:
                                                                        "",
                                                                      disabled:
                                                                        _vm.disableFilters,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.liquidityRange,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.liquidityRange =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "liquidityRange",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center justify-content-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      {
                                                                        staticClass:
                                                                          "pl-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Gestion des actifs "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setConforme(
                                                                                    "asset"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setNonConforme(
                                                                                    "asset"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Non Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-range-slider",
                                                                  {
                                                                    attrs: {
                                                                      max: "10",
                                                                      min: "0",
                                                                      "thumb-label":
                                                                        "",
                                                                      "thumb-size": 26,
                                                                      filled:
                                                                        "",
                                                                      disabled:
                                                                        _vm.disableFilters,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.assetRange,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.assetRange =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "assetRange",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center justify-content-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      {
                                                                        staticClass:
                                                                          "pl-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Gestion des passifs "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setConforme(
                                                                                    "liability"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setNonConforme(
                                                                                    "liability"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Non Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-range-slider",
                                                                  {
                                                                    attrs: {
                                                                      max: "10",
                                                                      min: "0",
                                                                      "thumb-label":
                                                                        "",
                                                                      "thumb-size": 26,
                                                                      filled:
                                                                        "",
                                                                      disabled:
                                                                        _vm.disableFilters,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.liabilityRange,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.liabilityRange =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "liabilityRange",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center justify-content-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      {
                                                                        staticClass:
                                                                          "pl-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Gestion des risques "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setConforme(
                                                                                    "risk"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setNonConforme(
                                                                                    "risk"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Non Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-range-slider",
                                                                  {
                                                                    attrs: {
                                                                      max: "10",
                                                                      min: "0",
                                                                      "thumb-label":
                                                                        "",
                                                                      "thumb-size": 26,
                                                                      filled:
                                                                        "",
                                                                      disabled:
                                                                        _vm.disableFilters,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.riskRange,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.riskRange =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "riskRange",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("v-divider", {
                                                  staticStyle: {
                                                    color: "#ffffff",
                                                    "margin-top": "20px",
                                                  },
                                                }),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-center align-items-center",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center justify-content-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      {
                                                                        staticClass:
                                                                          "pl-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " performance range "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setConforme(
                                                                                    "performance"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setNonConforme(
                                                                                    "performance"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Non Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-range-slider",
                                                                  {
                                                                    attrs: {
                                                                      max: "100",
                                                                      min: "0",
                                                                      "thumb-label":
                                                                        "",
                                                                      "thumb-size": 26,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.performanceRange,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.performanceRange =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "performanceRange",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "8" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center justify-content-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      {
                                                                        staticClass:
                                                                          "pl-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Rentabilité commerciale "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setConforme(
                                                                                    "commercial"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setNonConforme(
                                                                                    "commercial"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Non Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-range-slider",
                                                                  {
                                                                    attrs: {
                                                                      max: "10",
                                                                      min: "0",
                                                                      "thumb-label":
                                                                        "",
                                                                      "thumb-size": 26,
                                                                      filled:
                                                                        "",
                                                                      disabled:
                                                                        _vm.disableFilters,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.commercialRange,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.commercialRange =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "commercialRange",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center justify-content-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      {
                                                                        staticClass:
                                                                          "pl-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Rentabilité économique "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setConforme(
                                                                                    "economic"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setNonConforme(
                                                                                    "economic"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Non Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-range-slider",
                                                                  {
                                                                    attrs: {
                                                                      max: "10",
                                                                      min: "0",
                                                                      "thumb-label":
                                                                        "",
                                                                      "thumb-size": 26,
                                                                      filled:
                                                                        "",
                                                                      disabled:
                                                                        _vm.disableFilters,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.economicRange,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.economicRange =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "economicRange",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center justify-content-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      {
                                                                        staticClass:
                                                                          "pl-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Rentabilité financière "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setConforme(
                                                                                    "financial"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setNonConforme(
                                                                                    "financial"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Non Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-range-slider",
                                                                  {
                                                                    attrs: {
                                                                      max: "10",
                                                                      min: "0",
                                                                      "thumb-label":
                                                                        "",
                                                                      "thumb-size": 26,
                                                                      filled:
                                                                        "",
                                                                      disabled:
                                                                        _vm.disableFilters,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.financialRange,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.financialRange =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "financialRange",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center justify-content-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      {
                                                                        staticClass:
                                                                          "pl-0",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Rentabilité d'exploitation "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setConforme(
                                                                                    "operating"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "ma-2",
                                                                            attrs:
                                                                              {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.disableFilters,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setNonConforme(
                                                                                    "operating"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Non Conforme"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-range-slider",
                                                                  {
                                                                    attrs: {
                                                                      max: "10",
                                                                      min: "0",
                                                                      "thumb-label":
                                                                        "",
                                                                      "thumb-size": 26,
                                                                      filled:
                                                                        "",
                                                                      disabled:
                                                                        _vm.disableFilters,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.operatingRange,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.operatingRange =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "operatingRange",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-expansion-panel",
                                          [
                                            _c("v-expansion-panel-header", [
                                              _vm._v("Agrégat Financier"),
                                            ]),
                                            _c(
                                              "v-expansion-panel-content",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "4" } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center ",
                                                          },
                                                          [
                                                            _c(
                                                              "v-subheader",
                                                              {
                                                                staticClass:
                                                                  "pl-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Chiffre d’affaires "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center m-2",
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Min",
                                                                disabled:
                                                                  _vm.disableFilters,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restrictInput(
                                                                      $event,
                                                                      "chiffreAffaireMin"
                                                                    )
                                                                  },
                                                                blur: _vm.onFilterChiffreChanged,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.chiffreAffaireMin,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.chiffreAffaireMin =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "chiffreAffaireMin",
                                                              },
                                                            }),
                                                            _c("v-spacer"),
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Max",
                                                                disabled:
                                                                  _vm.disableFilters,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restrictInput(
                                                                      $event,
                                                                      "chiffreAffaireMax"
                                                                    )
                                                                  },
                                                                blur: _vm.onFilterChiffreChanged,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.chiffreAffaireMax,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.chiffreAffaireMax =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "chiffreAffaireMax",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "4" } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center ",
                                                          },
                                                          [
                                                            _c(
                                                              "v-subheader",
                                                              {
                                                                staticClass:
                                                                  "pl-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Résultat net "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center m-2",
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Min",
                                                                disabled:
                                                                  _vm.disableFilters,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restrictInput(
                                                                      $event,
                                                                      "resNetMin"
                                                                    )
                                                                  },
                                                                blur: _vm.onFilterResNetChanged,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.resNetMin,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.resNetMin =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "resNetMin",
                                                              },
                                                            }),
                                                            _c("v-spacer"),
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Max",
                                                                disabled:
                                                                  _vm.disableFilters,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restrictInput(
                                                                      $event,
                                                                      "resNetMax"
                                                                    )
                                                                  },
                                                                blur: _vm.onFilterResNetChanged,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.resNetMax,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.resNetMax =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "resNetMax",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "4" } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center ",
                                                          },
                                                          [
                                                            _c(
                                                              "v-subheader",
                                                              {
                                                                staticClass:
                                                                  "pl-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Total Actif "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center m-2",
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Min",
                                                                disabled:
                                                                  _vm.disableFilters,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restrictInput(
                                                                      $event,
                                                                      "totalActifMin"
                                                                    )
                                                                  },
                                                                blur: _vm.onFilterTotalActiffChanged,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.totalActifMin,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.totalActifMin =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "totalActifMin",
                                                              },
                                                            }),
                                                            _c("v-spacer"),
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Max",
                                                                disabled:
                                                                  _vm.disableFilters,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restrictInput(
                                                                      $event,
                                                                      "totalActifMax"
                                                                    )
                                                                  },
                                                                blur: _vm.onFilterTotalActiffChanged,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.totalActifMax,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.totalActifMax =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "totalActifMax",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "4" } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center ",
                                                          },
                                                          [
                                                            _c(
                                                              "v-subheader",
                                                              {
                                                                staticClass:
                                                                  "pl-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Total Passif "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center m-2",
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Min",
                                                                disabled:
                                                                  _vm.disableFilters,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restrictInput(
                                                                      $event,
                                                                      "totalPassifMin"
                                                                    )
                                                                  },
                                                                blur: _vm.onFilterTotalPassifChanged,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.totalPassifMin,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.totalPassifMin =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "totalPassifMin",
                                                              },
                                                            }),
                                                            _c("v-spacer"),
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Max",
                                                                disabled:
                                                                  _vm.disableFilters,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restrictInput(
                                                                      $event,
                                                                      "totalPassifMax"
                                                                    )
                                                                  },
                                                                blur: _vm.onFilterTotalPassifChanged,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.totalPassifMax,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.totalPassifMax =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "totalPassifMax",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "4" } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center ",
                                                          },
                                                          [
                                                            _c(
                                                              "v-subheader",
                                                              {
                                                                staticClass:
                                                                  "pl-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Capitaux Propres "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center m-2",
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Min",
                                                                disabled:
                                                                  _vm.disableFilters,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restrictInput(
                                                                      $event,
                                                                      "capitauxMin"
                                                                    )
                                                                  },
                                                                blur: _vm.onFilterCapitauxChanged,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.capitauxMin,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.capitauxMin =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "capitauxMin",
                                                              },
                                                            }),
                                                            _c("v-spacer"),
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Max",
                                                                disabled:
                                                                  _vm.disableFilters,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.restrictInput(
                                                                      $event,
                                                                      "capitauxMax"
                                                                    )
                                                                  },
                                                                blur: _vm.onFilterCapitauxChanged,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.capitauxMax,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.capitauxMax =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "capitauxMax",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-expansion-panel",
                              [
                                _c("v-expansion-panel-header", [
                                  _vm._v(_vm._s(_vm.$t("company.title"))),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c("grid-view", {
                                      ref: "grid",
                                      attrs: {
                                        headers: _vm.headers,
                                        endpoint: _vm.endpoint,
                                        "single-select": false,
                                        "sort-by": ["score"],
                                        "sort-desc": [true],
                                        model: "company",
                                        "show-select": "",
                                      },
                                      on: {
                                        "selection-change":
                                          _vm.onSelectionChange,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "item.name",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              ),
                                              item.symbol
                                                ? _c("span", [
                                                    _vm._v(
                                                      " - " +
                                                        _vm._s(item.symbol) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.country.name",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.translate(
                                                      item.country.name
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.web",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              item.web
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "green",
                                                      },
                                                    },
                                                    [_vm._v("fa fa-check")]
                                                  )
                                                : _vm._e(),
                                              !item.web
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "red",
                                                      },
                                                    },
                                                    [_vm._v("fa fa-times")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item._actions",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.viewCompany(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-eye")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-expansion-panel",
                              [
                                _c("v-expansion-panel-header", [
                                  _vm._v("Versions"),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "v-container",
                                      { attrs: { fluid: "" } },
                                      [
                                        _c(
                                          "v-row",
                                          _vm._l(
                                            this.composition,
                                            function (comp) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: comp.id,
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  comp.errorValidation
                                                    ? _c(
                                                        "v-alert",
                                                        {
                                                          attrs: {
                                                            dense: "",
                                                            icon: "mdi-alert",
                                                            value:
                                                              comp.alertDismiss,
                                                            type: "error",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              comp.errorMessage
                                                            ) + " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          _vm._l(
                                            this.composition,
                                            function (comp, index) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: comp.id,
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "1" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              readonly: _vm.fmp,
                                                              label:
                                                                _vm.$t("Close"),
                                                            },
                                                            model: {
                                                              value: comp.close,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    comp,
                                                                    "close",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "comp.close",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "2" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              readonly: _vm.fmp,
                                                              label: _vm.$t(
                                                                "models.company.self"
                                                              ),
                                                            },
                                                            model: {
                                                              value: comp.name,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    comp,
                                                                    "name",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "comp.name",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "1" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              readonly: "",
                                                              label:
                                                                _vm.$t(
                                                                  "Currency"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                comp.company_currency,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    comp,
                                                                    "company_currency",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "comp.company_currency",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "1" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              readonly: "",
                                                              label: "score",
                                                            },
                                                            model: {
                                                              value: comp.score,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    comp,
                                                                    "score",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "comp.score",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "2" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              readonly: "",
                                                              label:
                                                                _vm.$t(
                                                                  "Country"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                comp.country,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    comp,
                                                                    "country",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "comp.country",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "1" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              disabled:
                                                                !comp.company_id,
                                                              label:
                                                                _vm.$t(
                                                                  "nbrAction"
                                                                ),
                                                            },
                                                            on: {
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.updateNbrAction(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                comp.nbr_action,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    comp,
                                                                    "nbr_action",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "comp.nbr_action",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "1" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              readonly: "",
                                                              label: "total",
                                                            },
                                                            model: {
                                                              value: comp.valo,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    comp,
                                                                    "valo",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "comp.valo",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "2" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-datetime-picker",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.$t(
                                                                    "startDateVariation"
                                                                  ),
                                                                dateFormat:
                                                                  "yyyy-MM-dd",
                                                                timeFormat:
                                                                  "HH:mm",
                                                                datePickerProps:
                                                                  {
                                                                    min: _vm.formatedStartVariation,
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  comp.start_date,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      comp,
                                                                      "start_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "comp.start_date",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "dateIcon",
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "fas fa-calendar"
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "timeIcon",
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "fas fa-clock"
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-center align-items-center",
                                                          attrs: { cols: "1" },
                                                        },
                                                        [
                                                          comp.added
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "m-l-2",
                                                                  attrs: {
                                                                    icon: "",
                                                                    color:
                                                                      "error",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeComp(
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        "mdi-delete"
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              )
                                                            : [
                                                                comp.loadingValidation
                                                                  ? [
                                                                      _c(
                                                                        "v-progress-circular",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              indeterminate:
                                                                                "",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  : [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          staticClass:
                                                                            "m-l-2",
                                                                          attrs:
                                                                            {
                                                                              icon: "",
                                                                              color:
                                                                                "success",
                                                                              disabled:
                                                                                !comp.valo ||
                                                                                comp.valo ==
                                                                                  0 ||
                                                                                !comp.company_currency ||
                                                                                !comp.start_date,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.addCompany(
                                                                                  comp,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  "mdi-check"
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                              ],
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-end",
                                              },
                                              [
                                                _vm.exist
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ma-2",
                                                        attrs: {
                                                          disabled:
                                                            _vm
                                                              .selectedCompanies
                                                              .length === 0,
                                                          small: "",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.uploadData()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              left: "",
                                                              dark: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-upload "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" Enregistrer "),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-row", [
                                          _vm.loadingData
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block",
                                                  },
                                                },
                                                [
                                                  _c("v-progress-circular", {
                                                    attrs: {
                                                      indeterminate: "",
                                                    },
                                                  }),
                                                  _vm._v(" loading data .. "),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("upload-error", {
                                      attrs: { error: _vm.error },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _vm.lastVersion
                                          ? _c("h4", { staticClass: "ma-1" }, [
                                              _vm._v(" Last version : "),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.lastVersion.name) +
                                                    " - " +
                                                    _vm._s(
                                                      _vm.lastVersion
                                                        .date_version
                                                    )
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _vm.lastVersion
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "m-l-2",
                                                attrs: {
                                                  icon: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isVersionPopup = true
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(_vm._s("mdi-history")),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.lastVersion
                                      ? _c("v-simple-table", [
                                          _c("thead", [
                                            _c("tr", [
                                              _c("th", [_vm._v("Name")]),
                                              _c("th", [_vm._v("Action")]),
                                              _c("th", [_vm._v("Close")]),
                                              _c("th", [
                                                _vm._v("Valorisation"),
                                              ]),
                                              _c("th", [_vm._v("Global")]),
                                              _c("th", [_vm._v("Solidité")]),
                                              _c("th", [_vm._v("Performance")]),
                                              _c("th", [_vm._v("%")]),
                                            ]),
                                          ]),
                                          _c(
                                            "tbody",
                                            [
                                              _vm._l(
                                                _vm.lastVersion
                                                  .portfolioComposition,
                                                function (comp, ind) {
                                                  return _c(
                                                    "tr",
                                                    { key: ind },
                                                    [
                                                      _c(
                                                        "td",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticStyle: {
                                                                "align-items":
                                                                  "flex-start",
                                                              },
                                                              attrs: {
                                                                icon: "",
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteCompany(
                                                                      comp.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fa fa-trash"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticStyle: {
                                                                "align-items":
                                                                  "flex-start",
                                                              },
                                                              attrs: {
                                                                icon: "",
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.editCompany(
                                                                      comp.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-pencil"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.truncatedName(
                                                                  comp.company
                                                                    .name
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      !comp.editing
                                                        ? _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "selected-item",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  comp.nbr_action
                                                                ) + " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "selected-item",
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.$t(
                                                                        "Nbr action"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    blur: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editNbrAction(
                                                                        comp
                                                                      )
                                                                    },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      comp.nbr_action,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          comp,
                                                                          "nbr_action",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "comp.nbr_action",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "selected-item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              comp["close"]
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "selected-item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(comp.valo)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "selected-item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(comp.global)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "selected-item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              comp.solidite
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "selected-item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              comp.performance
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "selected-item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              comp.percentage
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm.selectedCompanies.length == 0
                                                ? _c("tr", [
                                                    _c("td", [
                                                      _vm._v(
                                                        " No data available ! "
                                                      ),
                                                    ]),
                                                    _c("td"),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("v-spacer"),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-3" },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                readonly: "",
                                                value: _vm.total,
                                                step: ".3",
                                                type: "number",
                                                label:
                                                  _vm.$t("total portefeuille"),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                readonly: "",
                                                value: _vm.scoreMoy.toFixed(3),
                                                step: ".3",
                                                type: "number",
                                                label: _vm.$t("Moyenne Score"),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                readonly: "",
                                                value:
                                                  _vm.soliditeMoy.toFixed(3),
                                                step: ".3",
                                                type: "number",
                                                label:
                                                  _vm.$t("Moyenne solidite"),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                readonly: "",
                                                value:
                                                  _vm.performanceMoy.toFixed(3),
                                                step: ".3",
                                                type: "number",
                                                label: _vm.$t(
                                                  "Moyenne performance"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.uploadingData
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              attrs: { indeterminate: "" },
                                            }),
                                            _vm._v(" Parsing data .. "),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.doneUpload
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                          },
                                          [_vm._v(" Done ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "4",
                                              sm: "6",
                                              md: "4",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-datetime-picker",
                                              {
                                                attrs: {
                                                  dateFormat: "yyyy-MM-dd",
                                                  timeFormat: "HH:mm",
                                                  label:
                                                    _vm.$t(
                                                      "startDateVariation"
                                                    ),
                                                },
                                                model: {
                                                  value: _vm.startVariation,
                                                  callback: function ($$v) {
                                                    _vm.startVariation = $$v
                                                  },
                                                  expression: "startVariation",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "dateIcon" },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("fas fa-calendar"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "template",
                                                  { slot: "timeIcon" },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("fas fa-clock"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticStyle: {
                                              display: "grid",
                                              "align-items": "center",
                                            },
                                            attrs: { cols: "8" },
                                          },
                                          [
                                            _vm.exist
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "ma-2",
                                                    attrs: {
                                                      disabled:
                                                        _vm.selectedCompanies
                                                          .length === 0,
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.uploadData()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          dark: "",
                                                        },
                                                      },
                                                      [_vm._v(" mdi-upload ")]
                                                    ),
                                                    _vm._v(" Enregistrer "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            !_vm.exist
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "ma-2",
                                                    attrs: {
                                                      disabled:
                                                        _vm.selectedCompanies
                                                          .length === 0 ||
                                                        !_vm.startVariation,
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.uploadData()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          dark: "",
                                                        },
                                                      },
                                                      [_vm._v(" mdi-upload ")]
                                                    ),
                                                    _vm._v(" Enregistrer "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-expansion-panel",
                              [
                                _c("v-expansion-panel-header", [
                                  _vm._v("Transactions"),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c("v-simple-table", [
                                      _c("thead", [
                                        _c("tr", [
                                          _c("th", [_vm._v("Name")]),
                                          _c("th", [_vm._v("Actions Number")]),
                                          _c("th", [_vm._v("Close")]),
                                          _c("th", [_vm._v("Valorisation")]),
                                          _c("th", [_vm._v("Action Date")]),
                                          _c("th", [_vm._v("Type")]),
                                        ]),
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.portfolioActions,
                                          function (action) {
                                            return _c(
                                              "tr",
                                              { key: action.id },
                                              [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      action["company"].name
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(action["nbr_action"])
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(action["close"])
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(action["valo"])
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      action["date_variation"]
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "td",
                                                  [
                                                    action["type"] > 0
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "green",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "mdi-arrow-up"
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "red",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "mdi-arrow-down"
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      _c("v-card-actions"),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function (ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "blue", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.snackbar = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Close ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [_vm._v(" " + _vm._s(_vm.text) + " ")]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600", persistent: "" },
          model: {
            value: _vm.openDialogVariationDate,
            callback: function ($$v) {
              _vm.openDialogVariationDate = $$v
            },
            expression: "openDialogVariationDate",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-4" },
            [
              _c("v-card-title", { staticClass: "text-h6 mb-2" }, [
                _vm._v(" Please set the start Variation date to start ! "),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-datetime-picker",
                            {
                              attrs: {
                                disabled: _vm.exist,
                                dateFormat: "yyyy-MM-dd",
                                timeFormat: "HH:mm",
                                label: _vm.$t("startDateVariation"),
                              },
                              model: {
                                value: _vm.startVariation,
                                callback: function ($$v) {
                                  _vm.startVariation = $$v
                                },
                                expression: "startVariation",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "dateIcon" },
                                [_c("v-icon", [_vm._v("fas fa-calendar")])],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "timeIcon" },
                                [_c("v-icon", [_vm._v("fas fa-clock")])],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.openDialogVariationDate = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        color: "primary",
                        disabled: !_vm.startVariation,
                      },
                      on: {
                        click: function ($event) {
                          _vm.openDialogVariationDate = false
                        },
                      },
                    },
                    [_vm._v(" save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1200" },
          model: {
            value: _vm.isVersionPopup,
            callback: function ($$v) {
              _vm.isVersionPopup = $$v
            },
            expression: "isVersionPopup",
          },
        },
        [_c("VersionsPopup", { attrs: { portfolio: _vm.portfolio } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }